.nav-button{
    color: #ffffff!important;
    background: linear-gradient(to top, #000000 0%,#941550 65%,#5E2751 100%)!important;
    transition: box-shadow 0.2s!important;
    border: none!important;
}

.nav-button-active{
    background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%)!important;
    color: #232323!important;
    transition: box-shadow 0.2s!important;
    border: none!important;
}

.nav-button:hover{
    background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%)!important;
    color: #232323!important;
    transition: box-shadow 0.2s!important;
}

.nav-button-active:hover{
    background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%)!important;
    color: #232323!important;
    transition: box-shadow 0.2s!important;
}