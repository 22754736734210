.card {
    background: var(--surface-card);
    /* padding: 2rem; */
    border-radius: 10px;
    margin-bottom: 1rem;
}

/* .flex{
  display: flex!important;
  
}

.justify-content-between{
  justify-content: space-between!important;
} */

.text-green-500{
    color: green!important;
}

.text-red-500{
    color: red!important;
}

.p-dialog-header{
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%)!important;
  padding: 0.8rem!important;
  color: #232323!important;
}

.p-dialog-title{
    color: #232323!important;
    font-weight: 1000!important;
    font-size: 1rem!important;
}

.p-dialog-mask {
    background-color: #0000001c!important;
}

.p-dialog-content{
    padding: 2rem 1.5rem 2rem 1.5rem!important;
}

@media (min-width: 1200px) {
    .outcome-dialog {
      width: 35vw; 
    }
  }
  
  @media (max-width: 767px) {
    .outcome-dialog {
      width: 90vw;
    }
  }
  
  @media (min-width: 768px) and (max-width: 980px) {
    .outcome-dialog {
      width: 80vw;
    }
  }

.rectangle-box {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    border: 1px solid rgba(128, 128, 128, 0.557);
    cursor: pointer;
    position: relative; /* Position relative for pseudo-element */
    overflow: hidden; /* Hide overflowing pseudo-element */
  }

  .rectangle-box-green {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    border: 1px solid rgb(27, 196, 120);
    cursor: pointer;
    position: relative; /* Position relative for pseudo-element */
    overflow: hidden; /* Hide overflowing pseudo-element */
  }
  .rectangle-box-yellow {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    border: 1px solid rgb(255 232 0);
    cursor: pointer;
    position: relative; /* Position relative for pseudo-element */
    overflow: hidden; /* Hide overflowing pseudo-element */
  }
  .rectangle-box-purple {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    border: 1px solid rgb(114 0 240);
    cursor: pointer;
    position: relative; /* Position relative for pseudo-element */
    overflow: hidden; /* Hide overflowing pseudo-element */
  }
  .rectangle-box-red {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    border: 1px solid rgb(255 0 0);
    cursor: pointer;
    position: relative; /* Position relative for pseudo-element */
    overflow: hidden; /* Hide overflowing pseudo-element */
  }
  
  .box-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(27, 196, 120)
  }
  .box-logo-green {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(27, 196, 120)
  }
  .box-logo-yellow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(255 232 0);
  }
  .box-logo-purple {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(114 0 240);
  }
  .box-logo-red {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(255 0 0);
  }

  .box-logo span {
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
  }

  .box-logo-green span {
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
  }
  .box-logo-yellow span {
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
  }
  .box-logo-purple span {
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
  }
  .box-logo-red span {
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
  }
  
  .box-content {
    flex: 1;
    background-color: white;
    height: 50px;
    padding-left: 1rem!important;
    transition: background-color 0.3s ease-in-out;
  }

  .box-content-selected{
    background-color: rgb(27, 196, 120);
    color: white;
    font-weight: bold;
  }
  .box-content-selected-green{
    background-color: rgb(27, 196, 120);
    color: white;
    font-weight: bold;
  }
  .box-content-selected-yellow{
    background-color: rgb(255 232 0);
    color: white;
    font-weight: bold;
  }
  .box-content-selected-purple{
    background-color: rgb(114 0 240);
    color: white;
    font-weight: bold;
  }
  .box-content-selected-red{
    background-color: rgb(255 0 0);
    color: white;
    font-weight: bold;
  }

  .rectangle-box:hover .box-content {
    background-color: rgb(27, 196, 120);
    color: white;
    font-weight: bold;
  }

  .rectangle-box-green:hover .box-content {
    background-color: rgb(27, 196, 120);
    color: white;
    font-weight: bold;
  }
  .rectangle-box-yellow:hover .box-content {
    background-color: rgb(255 232 0);
    color: white;
    font-weight: bold;
  }
  .rectangle-box-purple:hover .box-content {
    background-color: rgb(114 0 240);
    color: white;
    font-weight: bold;
  }
  .rectangle-box-red:hover .box-content {
    background-color: rgb(255 0 0);
    color: white;
    font-weight: bold;
  }


  .callNotes-container{
    margin-top: 2rem;
  }

  .callNotes-heading{
    margin-bottom: 3px!important;
  }

  .p-inputtext:enabled:hover {
    border-color:  rgb(44 56 148)!important;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #c7feeb!important;
  border-color: rgb(44 56 148)!important;
}

.submit-buttons-container{
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.cancel-button{
  margin-right: .5rem!important;
  height: 35px;
  border: none!important;
  background-color: rgba(128, 128, 128, 0.735)!important;
}

.record-button{
  height: 35px;
  border: none!important;
  background-color: rgb(44 56 148)!important;
}

.set-outcome-button{
  height: 35px;
  border: none!important;
  background-color: rgb(44 56 148)!important;

}

.set-redirect-button{
  height: 35px;
  border: none!important;
  background-color: rgb(72, 148, 44)!important;

}

.outcome-null{
  width: 100%;
    height: 69px;
    text-align: center;
    padding-top: 26px;
}
.outcome-Sale{
  width: 100%;
    height: 69px;
    color: white;
    background-color: rgb(27, 196, 120);
    text-align: center;
    padding-top: 26px;
}
.outcome-No.Sale{
  width: 100%;
    height: 69px;
    color: white;
    background-color: rgb(255 232 0);
    text-align: center;
    padding-top: 26px;
}
.outcome-Trial{
  width: 100%;
    height: 69px;
    color: white;
    background-color: rgb(114 0 240);
    text-align: center;
    padding-top: 26px;
}
.outcome-Did.Not.Show{
  width: 100%;
    height: 69px;
    color: white;
    background-color: rgb(255 0 0);
    text-align: center;
    padding-top: 26px;
}

.padding-0{
  padding: 0px!important;
  /* text-align: center!important; */
}


.p-datatable .p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.p-datatable .p-datatable-header {
  background: linear-gradient(to bottom, #000000 0%,#941550 65%,#5E2751 100%);
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #ffffff;
  background: linear-gradient(to top, #000000 0%,#941550 65%,#5E2751 100%);
  transition: box-shadow 0.2s;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
  color: #ffffff;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
  color: #ffffff;
}

.p-datatable .p-sortable-column.p-highlight {
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
  color: #ffffff;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #ffffff;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #ffffff;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #ffffff;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #ffffff;
  margin-left: 0.5rem;
}

.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
}

.stat-table tr {
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%)!important;
  color: #000000!important;
  font-weight: bold!important;
}

.header-flex{
  display: flex;
  gap: 1rem;
}

.header-flex-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.p-paginator {
  background: linear-gradient(to bottom, #000000 0%,#941550 65%,#5E2751 100%);
  color: #ffffff;
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #ffffff;
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
  border-color: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
  color: #495057;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
  border-color: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
  color: #495057;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #ffffff;
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
  border-color: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
  color: #495057;
}

.p-dropdown {
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
  border: 1px solid #ced4da;
  /* transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s; */
  border-radius: 6px;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #95783C;
}

.p-dropdown:not(.p-disabled).p-focus {
  border-color: #95783C;
  box-shadow: none;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #343434;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #ffffff;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-clear-button:hover {
  color: #343a40;
  border-color: transparent;
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
}

.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #343434;
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
  padding: 0.75rem 0.75rem;
  border: 1px solid #95783C;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #C4B069!important;
  border-color: 95783C!important;
}

.p-button.p-button-outlined:enabled:hover, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgba(99, 102, 241, 0.04);
  color: #C4B069;
  border: 1px solid;
}

.p-button:focus {
  box-shadow: 0 0 0 2px #95783C;
}

.p-button {
  color: #ffffff;
  background: rgba(99, 102, 241, 0.04);
  border: 1px solid #ffffff;
  border-left: none;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}

.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(99, 102, 241, 0.04);
  color: #C4B069;
  border: 1px solid;
}

.p-datepicker-buttonbar button[aria-label="Today"] {
  display: none;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%);
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  color: #C4B069;
  box-shadow: 0 0 0 0.1rem #C4B069;
}

.p-inputnumber {
  display: inline-flex;
  width: 85px!important;
}

.p-inputnumber-input {
  flex: 1 1 auto;
  width: 85px;
}

.stat-first-column{
  color: #ffffff;
  background: linear-gradient(to top, #000000 0%,#941550 65%,#5E2751 100%);
  transition: box-shadow 0.2s;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #C4B069!important;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #393939;
}

.redirect-button{
  color: #6c757d;
  background: #ffffff;
  border-radius: 50%;
  padding: .6rem!important;
  font-size: xx-small;
}

.redirect-button:hover{
  background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%)!important;
  color: #232323!important;
  border: none!important;

}