.p-dialog .p-dialog-content {
    background: linear-gradient(to top, #000000 0%,#941550 65%,#5E2751 100%);
    color: #ffffff;
    padding: 0 1.5rem 2rem 1.5rem;
}

.form-row{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 2rem;
}

.form-column{
    display: flex;
    flex-direction: column;
    gap: .3rem;
    width: 48%;
}

.p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: linear-gradient(to bottom, #95783C 0%,#C4B069 50%,#95783C 100%)!important;
    color: #495057;
    padding: .5rem;
    text-align: right;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.form-row .p-inputnumber {
    display: inline-flex;
    width: 100%!important;
}

.form-row .p-inputnumber-input {
    flex: none;
    width: 100%!important;
}

.p-toolbar {
    background: linear-gradient(to top, #000000 0%,#941550 65%,#5E2751 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 0px!important;
}

.confirmation-content{
    display: flex;
    gap: 1rem;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #95783C;
    background: #95783C;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #C4B069;
    background: #C4B069;
  }
  .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: #95783C;
    background: #95783C;
    color: #ffffff;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #C4B069;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #fff4cb;
    border-color: #C4B069;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #95783C;
    background: #95783C;
    color: 
    #ffffff;
  }

  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: rgb(252, 248, 233);
    color: #95783C;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #95783C;
    background: rgb(252, 248, 233);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #95783C;
    background: rgb(252, 248, 233);
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #fff4cb;
  }